<template>
  <Content>
    <template #contentBody>
      <a-row>
        <a-col :span="24">
          <a-steps :current="2" style="max-width: 1800px" size="small">
            <a-step :title="$t('warehouse.create_allot_to_storage_of_plan')" />
            <a-step :title="$t('warehouse.set_packing_plan')" />
            <a-step :title="$t('warehouse.confirm_transfer_plan')" />
            <a-step :title="$t('common.completed')" />
          </a-steps>
        </a-col>
      </a-row>
      <div class="mt-3 mb-3">
        <p>
          {{ $t("warehouse.consignment_warehouse_code") }}:
          {{ state?.warehouse?.warehouseNo }}
          <span class="ml-5" v-if="state.warehouseInfo"
            >{{ $t("logistics.contacts") }}:
            {{ getLinkman(state.warehouseInfo) }}</span
          >
        </p>
      </div>

      <a-table
        :columns="columns"
        :data-source="state?.boxList"
        size="small"
        style="min-height: 223px"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :pagination="false"
      >

        <template #packMaterialName="{ record }">
          <span v-if="record?.id">
          {{ record.materialTypeCnName }}
          </span>
          <span v-else>-</span>
        </template>

        <template #materialsType="{ record }">
          <span v-if="record?.id">
          {{ record.materialTypeCnName }}/{{ record.materialTypeEnName }}
          </span>
          <span v-else>-</span>
        </template>

        <template #outerDiameterSize="{ record }">
          <span v-if="record.packMaterialLength">
              {{ record.packMaterialLength }}x{{ record.packMaterialWidth }}x{{
                record.packMaterialHeight
              }}
              cm
            </span>
            <span v-if="record?.innerData[0]?.packingLength && !record.id">
              {{ record.innerData[0].packingLength }}x{{ record.innerData[0].packingWidth }}x{{
                record.innerData[0].packingHeight
              }}
              cm
            </span>
        </template>

        <template #weight="{ record }">
          <span v-if="record.packMaterialWeight">
            {{ gToKg(record.packMaterialWeight) }} kg
          </span>
          <span v-else> - </span>
        </template>

        <template #price="{ record }">
          <span v-if="record?.id">
          {{ record.currencySymbol }}
          {{ $filters.amountToFixed2(record.price) }}
          </span>
          <span v-else>-</span>
        </template>

        <template #totalWeight="{ record }">
          <span v-if="record?.id">
            <span v-if="record.isSupported && record.totalWeightPack">
              <span v-if="record.totalWeightPack">
                {{ gToKg(record.totalWeightPack) }} kg
              </span>
              <span v-else>-</span>
            </span>
            <span v-else>
              {{ $t("warehouse.not_applicable") }}
            </span>
          </span>
          <span v-else>
              {{gToKg(record?.innerData[0]?.packingWeight *  (record?.innerData[0]?.numEdit||record?.innerData[0]?.num))}} kg
          </span>

        </template>

        <template #footer v-if="state.totalBox > 0">
          <a-row :gutter="[64, 8]">
            <a-col
              >{{ $t("common.total") }}{{ state.totalBox
              }}{{ $t("warehouse.box") }}</a-col
            >
            <a-col>{{ $t("common.p0_products", [state.totalProduct]) }}</a-col>
            <a-col>{{ $t("common.total") }}{{ state.totalUnit }} Unit</a-col>
          </a-row>
        </template>

        <template #expandedRowRender="{ record }">
          <a-table
            :columns="innerColumns"
            :data-source="record.innerData"
            :pagination="false"
            :row-key="(record, index) => index"
            size="small"
          >
            <template #productInfo="{ record }">
              <a-space>
                <div class="table-list-img-common">
                  <c-image :src="record.imgUrl"></c-image>
                </div>
                <div>
                  <div>{{ record.productName }}</div>
                  <div>{{ record.productNo }}</div>
                </div>
              </a-space>
            </template>

            <template #product_size="{ record }">
              <span v-if="record?.packingLength">
                {{ record?.packingLength }}x{{ record?.packingWidth }}x{{
                  record?.packingHeight
                }}
                cm
              </span>
              <span v-else>-</span>
            </template>

            <template #weight="{ record }">
              <span v-if="record?.packingWeight">
                {{ gToKg(record?.packingWeight) }} kg
              </span>
              <span v-else>-</span>
            </template>

            <template #encasement_count="{ record }">
              {{ record.numEdit || record.num }} Unit
            </template>

            <template #volume="{ record }">
              <span v-if="record?.packingVolume">
                {{ cmCubicToM(record?.packingVolume) }} m<sup>3</sup>
              </span>
              <span v-else>-</span>
            </template>

            <template #product_label="{ record }">
              <span v-if="record.label">
                {{ $t("warehouse.label_serve") }}:
                {{ record?.label?.name }}
              </span>
              <span v-else>
                {{ $t("warehouse.unlabeled_service") }}
              </span>
            </template>

            <template #extra_tags="{ record }">
             <CFileDisplay v-if="record.otherLabelUrl" :fileUrl="record.otherLabelUrl" />
             <span v-else>-</span>
            </template>
          </a-table>
        </template>
      </a-table>

      <div class="mt-3" v-if="state.boxList?.length > 0">
        <div>
          {{ $t("warehouse.product_information_summary") }}
        </div>
        <a-table
          :columns="productSummaryColumns"
          :data-source="state.productSummary"
          :pagination="false"
          :row-key="(record, index) => index"
          size="small"
        >
          <template #productInfo="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.imgUrl"></c-image>
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>

          <template #product_size="{ record }">
            <span v-if="record?.packingLength">
              {{ record?.packingLength }}x{{ record?.packingWidth }}x{{
                record?.packingHeight
              }}
              cm
            </span>
            <span v-else>-</span>
          </template>

          <template #weight="{ record }">
            <span v-if="record?.packingWeight">
              {{ gToKg(record?.packingWeight) }} kg
            </span>
            <span v-else>-</span>
          </template>

          <template #current_warehouse_usable_inventory="{ record }">
            {{ record?.count }} Unit
          </template>

          <template #encasement_count="{ record }">
            {{ record.allotNum }} Unit
          </template>
        </a-table>
      </div>

      <!-- 费用明细 -->
      <div class="mt-1" v-if="state.fee?.feeData">
        <CFee
          :fees="state.fee?.fees"
          :totalFee="{
            value: state.fee?.feeData?.totalAmount,
            symbol: state.fee?.feeData?.currencySymbol,
          }"
        ></CFee>
      </div>

      <!-- button 返回和支付modal -->
      <div class="mr-5 mb-5 mt-3">
        <a-row type="flex" justify="end">
          <a-col class="ml-3">
            <a-button @click="handleBack">{{
              $t("common.backtrack")
            }}</a-button>
          </a-col>
          <a-col class="ml-3">
            <a-button type="primary" @click="handleShowPayOrder">
              {{ $t("warehouse.confirm_and_pay") }}
            </a-button>
          </a-col>
        </a-row>
      </div>

      <Payment
        ref="refPayment"
        :currencyId="state.fee.feeData?.currencyId ?? ''"
        :unpaidSymbol="state.fee.feeData?.currencySymbol ?? ''"
        :unpaidSum="state.fee.feeData?.totalAmount ?? 0"
        @handlePayFee="handleOrderPay"
        :loading="state.payModal.loading"
        v-model:visible="state.payModal.visible"
      ></Payment>
    </template>
  </Content>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import Content from "../../components/Content.vue";
import {
  Row,
  Col,
  Steps,
  Typography,
  Radio,
  Spin,
  Table,
  Input,
  InputNumber,
  Button,
  Form,
  Modal,
  Space,
  message,
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import CPager from "../../components/CPager.vue";
import {
  payOrderAandCreatePlan,
  getFee,
} from "../../../api/modules/consignment/allot";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useTab } from "../../../hooks/tabs/index";
import CImage from "../../components/CImage.vue";
import { gToKg, getLinkman, cmCubicToM } from "../../../utils/general";
import { useStore } from "vuex";
import SearchProduct from "../../components/SearchProduct.vue";
import {
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "../../../utils/store";
import { allotPlanKeys } from "../../../consts/index";
import Payment from "../../components/Payment.vue";
import CFileDisplay from "../../components/CFileDisplay.vue";
import CFee from "../../components/CFee.vue";
import filters from "@/filters";

export default {
  name: "consignment_allot_confirm",
  components: {
    CFee,CFileDisplay,
    CImage,
    Payment,
    Content,
    CPager,
    SearchProduct,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ATypographyText: Typography.Text,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ASpin: Spin,
    ASpace: Space,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const refPayment = ref(null);
    const router = useRouter();
    const { delVisitedRoute, visitedRoutes } = useTab();
    const { getters } = useStore();

    const columns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 50,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        title: () => vueI18n.t("warehouse.packing_material_name"),
        dataIndex: "packMaterialName",
        slots: {
          customRender: "packMaterialName",
        },
        width: 200,
      },
      {
        title: () => vueI18n.t("warehouse.materials"),
        slots: {
          customRender: "materialsType",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.outer_diameter_size"),
        slots: {
          customRender: "outerDiameterSize",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.weight"),
        slots: {
          customRender: "weight",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.material_fee"),
        slots: {
          customRender: "price",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.total_weight_after_packing"),
        slots: {
          customRender: "totalWeight",
        },
        width: 100,
      },
    ];

    const innerColumns = [
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 80,
      },
      {
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
        width: 130,
      },
      {
        title: () => vueI18n.t("warehouse.product_size"),
        slots: {
          customRender: "product_size",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t("warehouse.weight"),
        slots: {
          customRender: "weight",
        },
        width: 70,
      },
      {
        title: () => vueI18n.t("warehouse.encasement_count"),
        slots: {
          customRender: "encasement_count",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.volume"),
        slots: {
          customRender: "volume",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.product_label"),
        slots: {
          customRender: "product_label",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.extra_tags"),
        slots: {
          customRender: "extra_tags",
        },
        width: 100,
      },
    ];

    const productSummaryColumns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 50,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 80,
      },
      {
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
        width: 130,
      },
      {
        title: () => vueI18n.t("warehouse.product_size"),
        slots: {
          customRender: "product_size",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t("warehouse.weight"),
        slots: {
          customRender: "weight",
        },
        width: 70,
      },
      {
        title: () => vueI18n.t("warehouse.current_warehouse_usable_inventory"),
        slots: {
          customRender: "current_warehouse_usable_inventory",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.allot_amount"),
        slots: {
          customRender: "encasement_count",
        },
        width: 100,
      },
    ];

    const funcGetFee = (record) => {
      let planProducts = state.boxList.map((x) => {
        let productDatas = x.innerData?.map((m) => {
          return {
            label: m.label,
            productId: m.productId,
            num: m.numEdit || m.num,
            otherLabelUrl: m.otherLabelUrl,
          };
        });
        return {
          packMaterialConfigId: x.id ?? null,
          productDatas: productDatas,
        };
      });

      let pra = {
        agentWarehouseId: state.warehouse.warehouseId,
        planProducts: planProducts,
      };
      state.parameter = pra;
      getFee(pra)
        .then((res) => {
          let { result } = res;
          if (result) {
            state.fee.feeData = result;
            state.fee.fees = result?.item?.map((f) => {
              return {
                value: f?.amount,
                feeType: f?.feeType,
                symbol: result.currencySymbol,
              };
            });
          }
        })
        .catch((e) => {});
    };

    const state = reactive({
      warehouse: null,
      warehousesList: [],
      boxList: [],
      productSummary: [],
      totalBox: 0,
      totalProduct: 0,
      totalUnit: 0,
      fee: {
        feeData: null,
        fees: null,
      },
      payModal: {
        visible: false,
        loading: false,
      },
    });

    const handleBack = async (record) => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "consignment_allot_set_box" });
    };

    const handleShowPayOrder = async () => {
      state.payModal.visible = true;
      refPayment.value.handleShowPayOrder();
    };

    const handleOrderPay = () => {
      state.parameter.payFee= filters.amountToFixed2(state.fee.feeData?.totalAmount);
      state.payModal.loading = true;
      payOrderAandCreatePlan(state.parameter)
        .then((res) => {
          if (res?.result?.planId) {
            message.success(vueI18n.t("common.succeed"));
            state.payModal.visible = false;
            delVisitedRoute(router.currentRoute.value);
            router.push({
              name: "consignment_allot_plan_details",
              params: { id: res.result.planId },
            });
          }
          state.payModal.loading = false;
        })
        .catch(() => {
          state.payModal.loading = false;
        });
    };

    const _getCachePageData = () => {
      let cacheData = getObjectSessionStorage(allotPlanKeys.setBoxPageData);
      if (cacheData) {
        Object.assign(state, cacheData);
        Object.assign(state, cacheData.state);
        state.parameter = null;
        funcGetFee();
      }
    };

    onMounted(async () => {
      _getCachePageData();
    });

    onBeforeRouteLeave((to, from, next) => {
      const cacheRoutes = [
        "consignment_allot_set_box",
        "consignment_allot_confirm",
      ];
      if (!cacheRoutes.includes(to.name)) {
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => cacheRoutes.includes(x.name)) >
          -1;
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(allotPlanKeys.setBoxPageData);
        }
      }
      next();
    });

    return {
      gToKg,
      cmCubicToM,
      getLinkman,

      state,
      columns,
      innerColumns,

      productSummaryColumns,

      refPayment,
      handleShowPayOrder,
      handleOrderPay,

      handleBack,
    };
  },
};
</script>

<style lang="less" scoped></style>
